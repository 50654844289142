.assign-to-search {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 10px;
  font-size: 11pt;
  font-weight: bold;
  color: #1b3a89;

  :first-child {
    padding-left: 10px; 
  }

  :last-child {
    position: absolute;
    right: 10px;
    bottom: 0;
  }

  input {
    padding: 2px 25px 2px 0;
    border: none;
    border-bottom: 2px solid #545454;
    outline: none;
    font-size: 12pt;
  }
}