@font-face {
  font-family: 'Graphik Web';
  src: url(../../static/media/Graphik-Regular-Web.8b5eccaa.woff2) format("woff2"), url(../../static/media/Graphik-Regular-Web.7546eab6.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'Graphik Web';
  src: url(../../static/media/Graphik-Semibold-Web.5bf9ce25.woff2) format("woff2"), url(../../static/media/Graphik-Semibold-Web.aa4bcd35.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'GTWalsheim';
  src: url(../../static/media/GT-Walsheim-Regular.7a59b7d2.woff2) format("woff2"), url(../../static/media/GT-Walsheim-Regular.82a96838.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'GTWalsheim';
  src: url(../../static/media/GT-Walsheim-Medium.33364eba.woff2) format("woff2"), url(../../static/media/GT-Walsheim-Medium.ef9c83dc.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal; }

body {
  margin: 0;
  padding: 0;
  font-family: "Graphik Web", sans-serif; }

textarea {
  font-family: "Graphik Web", sans-serif; }

/*overwrite table header sortable column names font color*/
th button div > span {
  padding: 0 10px !important;
  font-weight: bold !important;
  color: #000a46; }

.list-container {
  position: relative; }

.hidden {
  display: none; }

.flex-holder {
  display: flex; }

.right-block {
  margin: 0 0 0 10px; }

.card-container {
  padding: 60px 7%; }
  .card-container-title {
    margin-bottom: 12px;
    font-size: 20px; }
    .card-container-title-initials {
      text-transform: capitalize; }

.red {
  color: #d3455b; }

.description-section {
  display: flex; }
  @media (max-width: 800px) {
    .description-section {
      display: block; } }
  .description-section__item {
    margin-left: 80px; }
    .description-section__item:first-child {
      margin-left: 0; }
    @media (max-width: 800px) {
      .description-section__item {
        display: flex;
        flex-direction: column;
        margin: 0 0 10px; } }

/* Tabs style bottom border update */
.navigation .navigation__item {
  border-bottom-width: 4px !important; }

.tabs-container > div > div > div:nth-child(2) > div {
  height: 4px !important;
  margin-top: -4px !important; }

.tabbed-form > div > div > div:nth-child(2) > div {
  height: 4px !important;
  margin-top: -4px !important; }

.padding-top-20 {
  padding-top: 20px; }

.progress-indicator-wrapper {
  position: relative; }
  .progress-indicator-wrapper .progress-indicator {
    display: none; }
    .progress-indicator-wrapper .progress-indicator__active {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(146, 146, 146, 0.15);
      width: 100%;
      height: 100%;
      z-index: 100; }

.assign-to-search {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 10px;
  font-size: 11pt;
  font-weight: bold;
  color: #1b3a89; }
  .assign-to-search :first-child {
    padding-left: 10px; }
  .assign-to-search :last-child {
    position: absolute;
    right: 10px;
    bottom: 0; }
  .assign-to-search input {
    padding: 2px 25px 2px 0;
    border: none;
    border-bottom: 2px solid #545454;
    outline: none;
    font-size: 12pt; }

.applications-actions {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 0 15px;
  width: 100%;
  height: 50px;
  color: #fff;
  background-color: #2755c4; }
  .applications-actions > span {
    margin-right: 20px; }
  .applications-actions button {
    height: 100% !important; }
    .applications-actions button div > span {
      text-align: center;
      text-transform: none !important;
      font-size: 16px !important;
      color: #fff; }
    .applications-actions button:disabled span {
      text-align: center;
      text-transform: none !important;
      font-size: 16px !important;
      color: #9e9ea0; }

.date-range-picker__block {
    padding: 0 10px;
}

.date-range-picker__section {
    display: flex;
    align-items: center;
}

.date-range-picker {
    display: flex;
    align-items: flex-end;
}

.new-tab-link {
  color: #2755c4;
  font-weight: 600; }
  .new-tab-link:visited {
    color: #2755c4; }
  .new-tab-link:hover {
    text-decoration: none; }

.user-avatar {
  color: #000a46 !important;
  background-color: transparent !important;
  border: 1px solid #000a46;
  box-sizing: border-box;
  font-size: 12px !important;
  font-weight: 600;
  text-transform: uppercase !important; }
  .user-avatar-default {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px dashed #9e9ea0; }
    .user-avatar-default svg {
      width: 18px !important; }
  .user-avatar-internal {
    border: 1px solid #d4d6e0;
    box-sizing: border-box;
    object-fit: cover;
    object-position: 50% 10%; }

.applications-list > div > div {
  background-color: #f5f5f5 !important; }
  .applications-list > div > div .title > span {
    font-size: 20px !important;
    font-weight: bold; }
  .applications-list > div > div > div > div:first-child {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start; }
  .applications-list > div > div > div > div:nth-child(2) > div {
    display: flex;
    justify-content: flex-start !important; }

.applications-list th > span:first-child {
  top: 1.2px; }

.applications-list th span {
  font-size: 14px;
  text-transform: none; }

.applications-list th > span {
  display: block;
  font-weight: 600 !important;
  color: #000a46; }

.applications-list td {
  font-size: 12px !important; }
  .applications-list td > span {
    display: flex;
    justify-content: center; }
  .applications-list td div.selectable_chip > span {
    -webkit-user-select: text !important;
       -moz-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important; }

.applications-list .list-page {
  /* Targeting Filter & Search Section */
  /* Targeting Table Section */ }
  .applications-list .list-page > div > div > div:nth-child(2) {
    margin-top: -80px !important;
    width: calc(100% - 160px) !important;
    margin-left: 160px !important; }
  .applications-list .list-page > div > div > div:nth-child(3) > div:nth-child(2) {
    background-color: #F5F5F5 !important;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0; }

.student-applications > div > div {
  padding-top: 50px; }

.student-info {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .student-info :first-child {
    font-weight: bold;
    letter-spacing: 0.5px;
    text-transform: capitalize; }

.empty-state-container {
  display: flex;
  height: 20vh;
  flex-direction: column;
  justify-content: center;
  background-color: #fff; }
  .empty-state-container .message {
    text-align: center;
    font-family: Roboto, sans-serif;
    opacity: 0.5;
    margin: 0 1em; }

.summary-card-wrapper {
  margin-bottom: 30px; }
  .summary-card-wrapper .value-presenter-container {
    padding-bottom: 10px; }
  .summary-card-wrapper .summary-card-container {
    padding: 40px 50px 60px; }
    .summary-card-wrapper .summary-card-container .cover {
      display: flex;
      flex-wrap: wrap; }
      .summary-card-wrapper .summary-card-container .cover > .value-presenter-container,
      .summary-card-wrapper .summary-card-container .cover > .value-list-container {
        width: 50%; }
  .summary-card-wrapper .value-list-container.bold-label .value-list-label {
    font-weight: bold; }
  .summary-card-wrapper .summary-card-header {
    margin: 0;
    padding: 20px 50px;
    font-size: 20px;
    font-weight: 500; }
  .summary-card-wrapper.blue {
    border: 1px solid #000a46; }
    .summary-card-wrapper.blue .summary-card-header {
      color: #000a46;
      background-color: #d8e0f4; }
    .summary-card-wrapper.blue .summary-card-container {
      border-top: 1px solid #000a46;
      background-color: #fff; }
  .summary-card-wrapper.green .summary-card-header {
    background-color: #92ded7; }
  .summary-card-wrapper.grey .summary-card-header {
    background-color: #e9e6e6; }
  .summary-card-wrapper.grey .summary-card-container {
    background-color: #d4d6e1; }
  .summary-card-wrapper.yellow .summary-card-header {
    background-color: #ffd8b6; }
  .summary-card-wrapper.red {
    border: 1px solid #FF0000; }
    .summary-card-wrapper.red .summary-card-header {
      color: #FF0000;
      background-color: #d8e0f4; }
    .summary-card-wrapper.red .summary-card-container {
      border-top: 1px solid #FF0000;
      background-color: #fff; }

.comments-list {
  margin: 0;
  padding: 0 25px;
  list-style: none;
  font-size: 16px;
  color: #000a46; }
  .comments-list__item {
    padding: 10px 0 0; }
    .comments-list__item:first-child {
      padding-top: 0; }
  .comments-list__comment {
    border: 2px solid #4aae9f;
    background: #a3ded8;
    padding: 5px;
    min-height: 80px;
    box-sizing: border-box; }
    .comments-list__comment.red {
      border: 2px solid #d3455b;
      background: #edb4bd;
      padding: 5px;
      min-height: 80px;
      box-sizing: border-box; }
  .comments-list__text {
    padding: 1px 0 0;
    text-align: right; }
    .comments-list__text.info {
      font-size: 13px;
      font-style: italic; }

.textarea {
  border: 2px solid #9eadba;
  background: #dfe7ed;
  padding: 5px;
  min-height: 80px;
  box-sizing: border-box;
  display: block;
  font-size: 14px;
  line-height: 16px;
  margin: 0 0 10px;
  resize: none;
  color: #2b3845;
  width: 100%; }
  .textarea::-webkit-input-placeholder {
    font-style: italic;
    color: #293845; }
  .textarea::-ms-input-placeholder {
    font-style: italic;
    color: #293845; }
  .textarea::placeholder {
    font-style: italic;
    color: #293845; }
  .textarea:focus {
    outline: none; }

.comments-input {
  margin: 0 0 72px; }
  .comments-input__textarea {
    border: 2px solid #d4d6e0;
    padding: 20px;
    font-size: 16px;
    line-height: 20px;
    background: none;
    min-height: 104px; }
    .comments-input__textarea::-webkit-input-placeholder {
      color: #9e9ea0;
      font-style: normal; }
    .comments-input__textarea::-ms-input-placeholder {
      color: #9e9ea0;
      font-style: normal; }
    .comments-input__textarea::placeholder {
      color: #9e9ea0;
      font-style: normal; }
  .comments-input__button {
    padding: 0 15px;
    background-color: #2755c4 !important;
    border: 0;
    border-radius: 50px !important;
    color: #fff !important;
    font-size: 16px;
    line-height: 40px; }
    .comments-input__button:disabled {
      background-color: #757575 !important; }

.pinned-comments-holder {
  margin: 0 0 20px;
  padding: 22px;
  background-color: #f5f5f5;
  border: 2px solid #6491fd; }
  .pinned-comments-holder__title {
    font-size: 18px;
    color: #000a46;
    position: relative;
    display: block;
    padding: 0 0 0 10px;
    margin: 0 0 10px; }
    .pinned-comments-holder__title:before {
      content: '';
      position: absolute;
      left: 0;
      top: 6px;
      bottom: 4px;
      border-left: 1px solid #000a46; }
    .pinned-comments-holder__title:after {
      content: '';
      position: absolute;
      background-color: #000a46;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      top: 6px;
      left: 0.5px;
      -webkit-transform: translate(-3px, -3px);
              transform: translate(-3px, -3px); }
  .pinned-comments-holder__tip {
    position: absolute;
    top: 0;
    left: 0;
    background: #5f5f5f;
    color: #ffffff;
    padding: 0 10px;
    font-size: 12px;
    line-height: 25px; }
  .pinned-comments-holder .comments-list {
    padding: 0; }
    .pinned-comments-holder .comments-list-item {
      padding: 0; }

.comments-form {
  padding: 20px 0; }

.info-application {
  margin-left: 10px; }
  .info-application-by {
    margin-right: 12px;
    font-size: 16px;
    font-weight: 600; }
  .info-application-date {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    font-size: 12px;
    line-height: 2;
    color: #434c64; }

.comments-list-item {
  display: flex;
  box-sizing: border-box;
  padding: 15px 0;
  width: 100%; }

.comment-wrapper {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  font-size: 16px; }
  .comment-wrapper .comment-container {
    display: flex; }
    .comment-wrapper .comment-container .comment-text {
      font-size: 16px;
      max-width: 65%; }

.history-list-wrapper {
  padding: 20px 0; }
  .history-list-wrapper .history-list-item {
    display: flex;
    margin-bottom: 4px;
    padding: 25px;
    font-size: 16px;
    line-height: 24px;
    height: auto;
    background-color: #f5f5f5; }

.summary-info {
  display: flex; }
  .summary-info ul:first-child {
    margin-right: 20%; }

.info-list {
  margin: 0;
  padding: 0 0 30px;
  width: 40%;
  list-style: none; }
  .info-list li {
    display: flex;
    padding: 25px 0 0; }
    .info-list li:first-child {
      padding-top: 0; }
  .info-list__icon {
    margin-right: 10px; }
  .info-list__text {
    border-bottom: 2px solid #d4d6e0;
    width: 100%; }
    .info-list__text span {
      display: block;
      max-width: 240px; }
  .info-list__contact {
    color: #2755c4;
    font-weight: 600; }

.numeric-list {
  margin: 0;
  padding: 0;
  list-style: none; }
  .numeric-list li {
    display: flex;
    margin: 0 0 10px; }

.schools-list {
  line-height: 35px; }
  @media (max-width: 800px) {
    .schools-list {
      -webkit-column-count: 1 !important;
              column-count: 1 !important; } }
  .schools-list__item {
    width: 50%;
    display: flex;
    flex: 1 1;
    justify-content: space-between; }

.description-list {
  font-size: 18px; }
  .description-list__link {
    text-decoration: none;
    font-weight: normal;
    color: #2755c4; }
    .description-list__link:hover {
      text-decoration: underline; }
  .description-list-title {
    font-size: 20px;
    font-weight: 500; }
  .description-list__value {
    display: flex;
    justify-content: center; }
  .description-list .grid-list__cell__holder {
    display: flex;
    min-height: 50px;
    max-width: 250px;
    padding: 0 20px;
    flex-direction: column;
    justify-content: center; }

.grid-list {
  border: none;
  font-size: 16px; }
  .grid-list__row {
    display: table-row; }
    .grid-list__row:nth-child(even) {
      background-color: #f5f7ff; }
    .grid-list__row:nth-child(odd) {
      background-color: #e9efff; }
  .grid-list__cell {
    font-weight: 500 !important;
    display: table-cell;
    vertical-align: middle; }

.summary-view-container .frank-status {
  color: #2755c4;
  font-weight: bold; }

.summary-view-container .college-list-items {
  display: flex;
  flex-wrap: wrap; }
  .summary-view-container .college-list-items .schools-item {
    margin: 0 10px 10px;
    font-size: 14px;
    line-height: 20px; }
    .summary-view-container .college-list-items .schools-item__holder {
      min-width: 300px;
      margin: 0 10px 0 0; }
    .summary-view-container .college-list-items .schools-item .right-block {
      min-width: 120px; }

.sensitive-data-row {
  display: flex;
  padding-bottom: 30px; }
  .sensitive-data-row__holder {
    color: #000a46;
    font-size: 16px;
    width: 300px; }
  .sensitive-data-row__label {
    margin: 0 0 7px;
    font-weight: 600;
    text-transform: capitalize; }
  .sensitive-data-row__button {
    display: flex;
    align-items: center;
    margin: 0 0 0 20px; }

.form-group-label__title {
  color: #000a46;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  margin-bottom: 10px; }

.form-group-label__description {
  color: #1b1b1b;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px; }

.simple-form {
  max-width: 600px; }
  .simple-form input[type="number"] {
    margin-top: 0 !important;
    text-align: right; }
    .simple-form input[type="number"]::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none; }
  .simple-form label {
    color: #000a46 !important; }
  .simple-form > div:last-child {
    padding: 0 16px !important;
    background-color: #fff !important; }
  .simple-form button svg {
    fill: #000a46 !important; }

.tabs-container {
  background-color: #f5f5f5; }
  .tabs-container > div {
    padding: 0 !important; }
    .tabs-container > div > div:first-child button {
      font-size: 18px !important;
      color: #fff !important;
      text-transform: none !important; }

.navigation {
  width: 100%;
  background-color: white;
  white-space: nowrap;
  display: flex; }
  .navigation__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1;
    height: 48px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-decoration: none;
    outline: none;
    font-size: 18px;
    font-weight: normal;
    color: white;
    background: #000a46;
    box-sizing: border-box; }
  .navigation .disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5; }

.login {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center; }
  .login-card {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 500px;
    align-items: center;
    justify-content: center;
    border-top: 4px solid; }
    @media (min-width: 520px) {
      .login-card {
        width: 500px; } }
    .login-card-title {
      display: block;
      text-align: center;
      font-size: 30px; }
    .login-card-form {
      box-sizing: border-box;
      width: 100%;
      padding: 0 1em 1em 1em; }
    .login-card-input {
      display: flex; }
    .login-card-actions {
      display: flex;
      justify-content: center; }
  .login-help {
    padding: 10px 5px;
    color: #606060; }
  .login-link {
    color: #2755c4;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600; }

.sidebar {
  padding: 17px 0;
  width: 100%;
  height: 100%;
  background-color: #000a46; }
  .sidebar-menu {
    display: flex;
    flex-direction: column; }
    .sidebar-menu div[role="presentation"] {
      width: 100% !important; }
      .sidebar-menu div[role="presentation"] div[role="menu"] {
        display: block !important;
        width: 100% !important; }
        .sidebar-menu div[role="presentation"] div[role="menu"] span {
          min-height: 34px !important;
          line-height: normal !important; }
          .sidebar-menu div[role="presentation"] div[role="menu"] span > div {
            height: 34px !important; }
  .sidebar-version {
    margin-top: 20px;
    align-self: center;
    font-size: 14px;
    color: #2755c4; }
  .sidebar-link {
    display: flex !important;
    align-items: center;
    padding: 0 0 0 25px;
    min-height: 34px !important;
    text-decoration: none;
    color: #fff;
    border-left: 4px solid;
    border-color: #000a46;
    font-size: 14px !important; }
    .sidebar-link__selected {
      background-color: #d0deff;
      border-color: #2755c4;
      color: #1d42a5; }

.customer-application-link {
  display: block;
  width: 100%;
  padding: 7px 16px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  color: #1b1b1be8;
  text-decoration: none; }
  .customer-application-link:hover {
    background-color: rgba(0, 0, 0, 0.1); }
  .customer-application-link:last-child {
    margin-bottom: 15px; }

.toggle-container {
  position: relative;
  max-width: 350px; }
  .toggle-container > div {
    margin-bottom: -20px; }
  .toggle-container > div > div > div {
    width: 64px !important; }
  .toggle-container-helper {
    display: inline-block;
    max-width: 200px;
    font-size: 11px;
    color: #434c64; }

