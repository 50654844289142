@import "../../../styles/mixins";

.textarea {
  @include color-box(#9eadba, #dfe7ed);
  display: block;
  font-size: 14px;
  line-height: 16px;
  margin: 0 0 10px;
  resize: none;
  color: #2b3845;
  width: 100%;


  &::placeholder {
    font-style: italic;
    color: #293845;
  }

  &:focus {
    outline: none;
  }
}
