@import '../../styles/variables.scss';

.sidebar {
  padding: 17px 0;
  width: 100%;
  height: 100%;
  background-color: $navyBlue;

  &-menu {
    display: flex;
    flex-direction: column;
  
    div[role="presentation"] {
      width: 100% !important;
  
      div[role="menu"] {
        display: block !important;
        width: 100% !important;

        span {
          min-height: 34px !important;
          line-height: normal !important;

          & > div {
            height: 34px !important;
          }
        }
      }
    }
  }

  &-version {
    margin-top: 20px;
    align-self: center;
    font-size: 14px;
    color: $cobaltBlue;
  }

  &-link {
    display: flex !important;
    align-items: center;
    padding: 0 0 0 25px;
    min-height: 34px !important;
    text-decoration: none;
    color: #fff;
    border-left: 4px solid;
    border-color: $navyBlue;
    font-size: 14px !important;

    &__selected {
      background-color: $dimBlue;
      border-color: $cobaltBlue;
      color: $lightBlue;
    }
  }
}