@import '../../../../styles/variables.scss';

.tabs-container {
  background-color: $lightGrey;

  & > div {
    padding: 0 !important;

    & > div {
      &:first-child {
        button {
          font-size: 18px !important;
          color: #fff !important;
          text-transform: none !important;
        }
      }
    }
  }
}