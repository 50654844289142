@import '../../styles/variables.scss';

.grid-list {
  border: none;
  font-size: 16px;

  &__row {
    display: table-row;

    &:nth-child(even) {
      background-color: $lightPaleGrey;
    }

    &:nth-child(odd) {
      background-color: $paleGrey;
    }
  }
  
  &__cell {
    font-weight: 500 !important;
    display: table-cell;
    vertical-align: middle;
  }
}
