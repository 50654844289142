@import "../../styles/mixins";

.comments-list-item {
  display: flex;
  box-sizing: border-box;
  padding: 15px 0;
  width: 100%;
}

.comment-wrapper {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  font-size: 16px;

  .comment-container {
    display: flex;

    .comment-text {
      font-size: 16px;
      max-width: 65%;
    }
  }
}
