@import "./styles/mixins";
@import "./styles/variables.scss";

@font-face {
  font-family: 'Graphik Web';
  src: url('./assets/fonts/Graphik-font/Graphik-Regular-Web.woff2') format('woff2'), url('./assets/fonts/Graphik-font/Graphik-Regular-Web.woff') format('woff');
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('./assets/fonts/Graphik-font/Graphik-Semibold-Web.woff2') format('woff2'), url('./assets/fonts/Graphik-font/Graphik-Semibold-Web.woff') format('woff');
  font-weight:  600;
  font-style:   normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'GTWalsheim';
  src: url('./assets/fonts/GTWalsheim-font/GT-Walsheim-Regular.woff2') format('woff2'), url('./assets/fonts/GTWalsheim-font/GT-Walsheim-Regular.woff') format('woff');
  font-weight: 400;
  font-style:   normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'GTWalsheim';
  src: url('./assets/fonts/GTWalsheim-font/GT-Walsheim-Medium.woff2') format('woff2'), url('./assets/fonts/GTWalsheim-font/GT-Walsheim-Medium.woff') format('woff');
  font-weight: 600;
  font-style:   normal;
  font-stretch: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Graphik Web", sans-serif;
}

textarea {
  font-family: "Graphik Web", sans-serif;
}

/*overwrite table header sortable column names font color*/
th button div > span {
  padding: 0 10px !important;
  font-weight: bold !important;
  color: $navyBlue;
}

.list-container {
  position: relative;
}

.hidden {
  display: none;
}

.flex-holder {
  display: flex;
}

.right-block {
  margin: 0 0 0 10px;
}

.card-container {
  padding: 60px 7%;

  &-title {
    margin-bottom: 12px;
    font-size: 20px;

    &-initials {
      text-transform: capitalize;
    }
  }
}

.red {
  color: #d3455b;
}

.description-section {
  display: flex;

  @include mobile() {
    display: block;
  };

  &__item {
    margin-left: 80px;

    &:first-child {
      margin-left: 0;
    }

    @include mobile() {
      display: flex;
      flex-direction: column;
      margin: 0 0 10px;
    };
  }
}

/* Tabs style bottom border update */
.navigation .navigation__item {
  border-bottom-width: 4px !important;
}

.tabs-container > div > div > div:nth-child(2) > div {
  height: 4px !important;
  margin-top: -4px !important;
}

.tabbed-form > div > div > div:nth-child(2) > div {
  height: 4px !important;
  margin-top: -4px !important;
}

.padding-top-20 {
  padding-top: 20px;
}
