.empty-state-container {
    display: flex;
    height: 20vh;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;

  .message {
    text-align: center;
    font-family: Roboto, sans-serif;
    opacity: 0.5;
    margin: 0 1em;
  }
}
