@import "../../styles/variables";

.form-group-label {
  $block-margin: 10px;

  &__title {
    color: $navyBlue;
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    margin-bottom: $block-margin;
  }

  &__description {
    color: $black-2;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: $block-margin;
  }
}
