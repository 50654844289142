@mixin color-box($bdcolor, $bgcolor) {
  border: 2px solid $bdcolor;
  background: $bgcolor;
  padding: 5px;
  min-height: 80px;
  box-sizing: border-box;
}

@mixin mobile() {
  @media (max-width: 800px) {
    @content;
  }
}
