@import '../../styles/variables.scss';

.info-application {
  margin-left: 10px;

  &-by {
    margin-right: 12px;
    font-size: 16px;
    font-weight: 600;
  }

  &-date {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    font-size: 12px;
    line-height: 2;
    color: $darkBlueGrey;
  }
}