@import "../../styles/variables";

.customer-application-link {
  display: block;
  width: 100%;
  padding: 7px 16px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  color: $black;
  text-decoration: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:last-child {
    margin-bottom: 15px;
  }
}

