.progress-indicator-wrapper {
  position: relative;

  .progress-indicator {
    display: none;

    &__active {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(146, 146, 146, 0.15);
      width: 100%;
      height: 100%;
      z-index: 100;
    }
  }
}
