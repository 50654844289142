@import '../../../../styles/variables.scss';

.applications-actions {
  position: absolute; 
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 0 15px;
  width: 100%;
  height: 50px;
  color: #fff;
  background-color: $cobaltBlue;

  & > span {
    margin-right: 20px;
  }

  button {
    height: 100% !important;

    div > span {
      text-align: center;
      text-transform: none !important;
      font-size: 16px !important;
      color: #fff;
    }

    &:disabled {
      span {
        text-align: center;
        text-transform: none !important;
        font-size: 16px !important;
        color: $blueyGrey;
      }
    }
  }
}