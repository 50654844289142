.login {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;

  &-card {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 500px;
    align-items: center;
    justify-content: center;
    border-top: 4px solid;
  
    @media (min-width: 520px) {
      width: 500px;
    }

    &-title {
      display: block;
      text-align: center;
      font-size: 30px;
    }
    
    &-form {
      box-sizing: border-box;
      width: 100%;
      padding: 0 1em 1em 1em;
    }
    
    &-input {
      display: flex;
    }
    
    &-actions {
      display: flex;
      justify-content: center;
    }
  }
  
  &-help {
    padding: 10px 5px;
    color: #606060;
  }
  
  &-link {
    color: #2755c4;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
  }
}
