@import "../../styles/mixins";

.schools-list {
  line-height: 35px;

  @include mobile() {
    column-count: 1 !important;
  }

  &__item {
    width: 50%;
    display: flex;
    flex: 1;
    justify-content: space-between;
  }
}
