.numeric-list {
  margin:0;
  padding:0;
  list-style:none;

  li {
    display: flex;
    margin: 0 0 10px;
  }
}
