.toggle-container {
  position: relative;
  max-width: 350px;

  & > div {
    margin-bottom: -20px;
  }

  & > div > div > div {
    width: 64px !important;
  }

  &-helper {
    display: inline-block;
    max-width: 200px;
    font-size: 11px;
    color: #434c64;
  }
}