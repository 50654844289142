@import '../../styles/variables.scss';

.summary-info {
  display: flex;

  ul:first-child {
    margin-right: 20%;
  }
}

.info-list {
  margin:0;
  padding:0 0 30px;
  width: 40%;
  list-style:none;

  li {
    display: flex;
    padding: 25px 0 0;

    &:first-child {
      padding-top: 0;
    }
  }
  
  &__icon {
    margin-right: 10px;
  }

  &__text {
    border-bottom: 2px solid $underlineGrey;
    width: 100%;

    span {
      display: block;
      max-width: 240px;
    }
  }

  &__contact {
    color: $cobaltBlue;
    font-weight: 600;
  }
}
