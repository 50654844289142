@import '../../styles/variables.scss';

.new-tab-link {
  color: $cobaltBlue;
  font-weight: 600;

  &:visited {
    color: $cobaltBlue;
  }

  &:hover {
    text-decoration: none;
  }
}