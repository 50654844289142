.simple-form {
  max-width: 600px;

  input[type="number"] {
    margin-top: 0 !important;
    text-align: right;

    &::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }

  label {
    color: #000a46 !important;
  }

  & > div {
    &:last-child {
      padding: 0 16px !important;
      background-color: #fff !important;
    }
  }

  button {
    svg {
      fill: #000a46 !important;
    }
  }
}