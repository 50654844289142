@import "../../styles/variables";

.summary-view-container {
  .frank-status {
    color: $cobaltBlue;
    font-weight: bold;
  }

  .college-list-items {
    display: flex;
    flex-wrap: wrap;

    .schools-item {
      margin: 0 10px 10px;
      font-size: 14px;
      line-height: 20px;

      &__holder {
        min-width: 300px;
        margin: 0 10px 0 0;
      }

      .right-block {
        min-width: 120px;
      }
    }
  }
}


