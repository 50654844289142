@import '../../../styles/variables.scss';

.pinned-comments-holder {
  $pinned-comments-holder-header-color: #000a46;
  $pinned-comments-holder-border-width: 3px;
  $pinned-comments-holder-tip-height: 25px;

  margin: 0 0 20px;
  padding: 22px;
  background-color: $lightGrey;
  border: 2px solid $cornflower;

  &__title {
    font-size: 18px;
    color: $pinned-comments-holder-header-color;
    position: relative;
    display: block;
    padding: 0 0 0 10px;
    margin: 0 0 10px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 6px;
      bottom: 4px;
      border-left: 1px solid $pinned-comments-holder-header-color;
    }

    &:after {
      $size: 6px;
      $half-size: $size / 2;

      content: '';
      position: absolute;
      background-color: $pinned-comments-holder-header-color;
      width: $size;
      height: $size;
      border-radius: $half-size;
      top: 6px;
      left: 0.5px;
      transform: translate(-$half-size, -$half-size);
    }
  }

  &__tip {
    position: absolute;
    top: 0;
    left: 0;
    background: #5f5f5f;
    color: #ffffff;
    padding: 0 10px;
    font-size: 12px;
    line-height: $pinned-comments-holder-tip-height;
  }

  .comments-list {
    padding: 0;

    &-item {
      padding: 0;
    }
  }
}
