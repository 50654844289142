@import "../../styles/variables";

.history-list-wrapper {
  padding: 20px 0;

  .history-list-item {
    display: flex;
    margin-bottom: 4px;
    padding: 25px;
    font-size: 16px;
    line-height: 24px;
    height: auto;
    background-color: $lightGrey;
  }
}
