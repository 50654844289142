@import "../../styles/variables";

.summary-card-wrapper {
  $header-border-saturation: 20%;
  $padding: 50px;

  margin-bottom: 30px;

  .value-presenter-container {
    padding-bottom: 10px;
  }

  .summary-card-container {
    padding: 40px $padding 60px;

    .cover {
      display: flex;
      flex-wrap: wrap;

      & > .value-presenter-container,
      & > .value-list-container {
        width: 50%;
      }
    }
  }

  .value-list-container {
    &.bold-label {
      .value-list-label {
        font-weight: bold;
      }
    }
  }

  .summary-card-header {
    margin: 0;
    padding: 20px $padding;
    font-size: 20px;
    font-weight: 500;
  }

  &.blue {
    border: 1px solid $navyBlue;

    .summary-card-header {
      color: $navyBlue;
      background-color: $paleBlue;
    }
    
    .summary-card-container {
      border-top: 1px solid $navyBlue;
      background-color: #fff;
    }
  }

  &.green {
    .summary-card-header {
      background-color: $green;
    }
  }
  &.grey {
    .summary-card-header {
      background-color: #e9e6e6;
    }

    .summary-card-container {
      background-color: #d4d6e1;
    }
  }
  &.yellow {
    .summary-card-header {
      background-color: $yellow;
    }
  }
  &.red {
    border: 1px solid $red;

    .summary-card-header {
      color: $red;
      background-color: $paleBlue;
    }    

    .summary-card-container {
      border-top: 1px solid $red;
      background-color: #fff;
    }
  }  
}
