@import '../../styles/variables.scss';

.navigation {
  width: 100%;
  background-color: white;
  white-space: nowrap;
  display: flex;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 48px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-decoration: none;
    outline: none;
    font-size: 18px;
    font-weight: normal;
    color: white;
    background: $navyBlue;
    box-sizing: border-box;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
}
