.sensitive-data-row {
  display: flex;
  padding-bottom: 30px;

  &__holder {
    color: #000a46;
    font-size: 16px;
    width: 300px;
  }

  &__label {
    margin: 0 0 7px;
    font-weight: 600;
    text-transform: capitalize;
  }

  &__button {
    display: flex;
    align-items: center;
    margin: 0 0 0 20px;
  }
}
