@import "../../../styles/mixins";
@import "../../../styles/variables.scss";

.comments-list {
  margin: 0;
  padding: 0 25px;
  list-style: none;
  font-size: 16px;
  color: $navyBlue;

  &__item {
    padding: 10px 0 0;

    &:first-child {
      padding-top: 0;
    }
  }

  &__comment{
    @include color-box(#4aae9f, #a3ded8);

    &.red {
      @include color-box(#d3455b, #edb4bd);
    }
  }

  &__text {
    padding: 1px 0 0;
    text-align: right;

    &.info {
      font-size: 13px;
      font-style: italic;
    }
  }
}
