$lightBlack: #757575;
$black: #1b1b1be8;
$black-2: #1b1b1b;
$green: #92ded7;
$grey: #dce7ec;
$yellow: #ffd8b6;
$blue: #3dcebf;
$navyBlue: #000a46;
$lightGrey: #f5f5f5;
$borderGrey: #979797;
$cobaltBlue: #2755c4;
$blueyGrey: #9e9ea0;
$underlineGrey: #d4d6e0;
$dateGrey: #606060;
$dimBlue: #d0deff;
$lightBlue: #1d42a5;
$darkBlueGrey : #434c64;
$paleBlue: #d8e0f4;
$jade: #00b291;
$paleGrey : #e9efff;
$lightPaleGrey : #f5f7ff;
$cornflower : #6491fd;
$red : #FF0000;
