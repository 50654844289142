@import "../../../styles/mixins";
@import "../../../styles/variables.scss";

.comments-input {
  margin: 0 0 72px;

  &__textarea {
    border: 2px solid $underlineGrey;
    padding: 20px;
    font-size: 16px;
    line-height: 20px;
    background: none;
    min-height: 104px;

    &::placeholder {
      color: #9e9ea0;
      font-style: normal;
    }
  }

  &__button {
    padding: 0 15px;
    background-color: $cobaltBlue !important;
    border: 0;
    border-radius: 50px !important;
    color: #fff !important;
    font-size: 16px;
    line-height: 40px;

    &:disabled {
      background-color: $lightBlack !important;      
    }
  }
}
