@import '../../styles/variables.scss';

.description-list {
  font-size: 18px;

  &__link {
    text-decoration: none;
    font-weight: normal;
    color: $cobaltBlue;

    &:hover {
      text-decoration: underline;
    }
  }

  &-title {
    font-size: 20px;
    font-weight: 500;
  }

  &__value {
    display: flex;
    justify-content: center;
  }

  .grid-list__cell__holder {
    display: flex;
    min-height: 50px;
    max-width: 250px;
    padding: 0 20px;
    flex-direction: column;
    justify-content: center;
  }
}
