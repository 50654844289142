@import '../../styles/variables.scss';

.user-avatar {
  color: $navyBlue !important;
  background-color: transparent !important;
  border: 1px solid $navyBlue;
  box-sizing: border-box;
  font-size: 12px !important;
  font-weight: 600;
  text-transform: uppercase !important;

  &-default {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px dashed $blueyGrey;
    
    svg {
      width: 18px !important;
    }
  }

  &-internal {
    border: 1px solid $underlineGrey;
    box-sizing: border-box;
    object-fit: cover;
    object-position: 50% 10%;
  }
}