@import '../styles/variables.scss';

.applications-list {
  & > div > div {
    background-color: $lightGrey !important;

    .title > span {
      font-size: 20px !important;
      font-weight: bold;
    }

    & > div > div {
      &:first-child {
        display: flex !important;
        flex-direction: column;
        align-items: flex-start;
      }

      &:nth-child(2) > div {
        display: flex;
        justify-content: flex-start !important;
      }
    }
  }

  th {
    & > span:first-child {
      top: 1.2px;
    }

    span {
      font-size: 14px;
      text-transform: none;
    }

    & > span {
      display: block;
      font-weight: 600 !important;
      color: $navyBlue;
    }
  }

  td {
    font-size: 12px !important;

    & > span {
      display: flex;
      justify-content: center;
    }

    div.selectable_chip > span {
      user-select: text !important;
    }    
  }

  .list-page {
    /* Targeting Filter & Search Section */
    & > div > div > div:nth-child(2) {
      margin-top: -80px !important;
      width: calc(100% - 160px) !important;
      margin-left: 160px !important;
    }
    /* Targeting Table Section */
    & > div > div > div:nth-child(3) {
      & > div:nth-child(2) {
        background-color: #F5F5F5 !important;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
      }
    }
  }
}

.student-applications {
  & > div > div {
    padding-top: 50px;
  }
}

.student-info {
  display: flex;
  flex-direction: column;
  width: 100%;

  :first-child {
    font-weight: bold;
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }
}
